import React from "react";

import { useChallengesData } from "app/hooks/use-challenges-data";

import * as stylesShared from "app/styles/shared";
import * as styles from "app/components/challenges-card-list.styles";

import CCard from "app/components/challenge-card";

const S = {
  ...stylesShared,
  ...styles,
};

const ChallengesList = ({ challenges, excluded, title, shadowColor }) => {

  // create list of tracts.
  const tracts = [];
  challenges.forEach(challenge => {
    if ( !tracts.includes(challenge.tract) ) {
      tracts.push(challenge.tract);
    }
  });
  

  return (
    <S.Container css={S.ccardContainer}>
      {title && <S.CCardListTitle>{title}</S.CCardListTitle>}

      <S.Tract>{ tracts[0] }</S.Tract>
      
      <S.CCardList>
        {challenges
          .filter(({ slug }) => excluded.indexOf(slug) === -1)
          .filter(({ tract}) => tracts[0] === tract)
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((challenge, idx) => (
            <CCard
              key={idx}
              {...challenge}
              shadowColor={shadowColor}
              css={S.CCardLI}
              baseUri="/challenges"
              viewDetailsLabel="View Challenge"
            />
          ))}
      </S.CCardList>

      <S.Tract>{ tracts[1] }</S.Tract>
      
      <S.CCardList>
        {challenges
          .filter(({ slug }) => excluded.indexOf(slug) === -1)
          .filter(({ tract}) => tracts[1] === tract)
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((challenge, idx) => (
            <CCard
              key={idx}
              {...challenge}
              shadowColor={shadowColor}
              css={S.CCardLI}
              baseUri="/challenges"
              viewDetailsLabel="View Challenge"
            />
          ))}
      </S.CCardList>
    </S.Container>
  );
};

ChallengesList.defaultProps = {
  challenges: [],
  excluded: [],
};

const ChallengesListWithData = (data) => {
  return <ChallengesList {...data} challenges={useChallengesData()} />;
};

export default ChallengesListWithData;
