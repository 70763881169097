import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import ChallengeCardList from "app/components/challenges-card-list";
import HeroBar, { heroBarField } from "app/components/hero-bar";
import Layout from "app/components/layout";
import SEOMeta, { seoField } from "app/components/seo";

import * as S from "app/components/page.styles";

const Challenges = ({ title, intro, ctaBlock, ...props }) => (
  <Layout>
    <SEOMeta title={_get(props, "title")} {..._get(props, "seo")} />
    <S.PageBody as="article">
      <S.PageHeader as="header">
        <S.PageCat as="h1">{title}</S.PageCat>
        <S.PageTitle as="p">{intro}</S.PageTitle>
      </S.PageHeader>
      <ChallengeCardList shadowColor={"#bde0e7"} />
      <HeroBar {...ctaBlock} />
    </S.PageBody>
  </Layout>
);

export default Challenges;

export const challengesFragment = graphql`
  fragment ChallengesPage on ContentJson {
    ...SEO
    title
    intro
    ...HeroBar
  }
`;

export const challengesFields = [
  seoField,
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  {
    label: "Intro",
    name: "intro",
    widget: "text",
  },
  heroBarField,
];
