import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import Challenges from "app/templates/challenges";

const ChallengesPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentJson(template: { eq: "challenges" }) {
        ...ChallengesPage
      }
    }
  `);

  return <Challenges {..._get(data, "contentJson")} />;
};

export default ChallengesPage;
