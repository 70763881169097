import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

const GET_CHALLENGES_QUERY = graphql`
  query {
    allChallengesJson {
      nodes {
        ...Challenge
        ...Winner
        tract
      }
    }
  }
`;

export const useChallengesData = () => {
  const data = useStaticQuery(GET_CHALLENGES_QUERY);
  return _get(data, "allChallengesJson.nodes") || [];
};

export default useChallengesData;
